.nav {
  display: flex;
  flex-direction: column;
  @include paragraph-large-2;
  @include xxl {
    flex-direction: row; }
  $self: &;

  & > li {
    position: relative;
    border-bottom: 1px solid $gray-700;
    @include xxl {
      border-bottom: none; }

    & > #{$self}__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      text-decoration: none;
      color: $white;
      transition: color .3s ease, border-bottom-color .3s ease;
      padding: .9375rem 1rem;
      @include xxl {
        padding: 0 0 .1875rem;
        border-bottom: 2px solid transparent;
        display: inline-block; }

      &:hover, &.is-active {
        color: $green; }

      &:hover {
        @include xxl {
          border-bottom-color: $green; } }

      @include page-gray {
        @include xxl {
          color: $black; }
        &:hover, &.is-active {
          @include xxl {
            color: $blue-dark; } }
        &:hover {
          @include xxl {
            border-bottom-color: $blue-dark; } } }

      img {
        margin-left: .5rem;
        transition: transform .3s ease;
        @include xxl {
          display: none; } } }

    &.is-sub-nav-shown {
      & > #{$self}__item {
        img {
          transform: rotate(90deg); } } }

    &:hover {
      .sub-nav {
        @include xl {
          opacity: 1;
          pointer-events: auto;
          margin-top: 0; } }

      &:before {
        @include xl {
          pointer-events: auto; } } }

    &.sub-nav-full-width {
      @include xl {
        position: static; }

      .sub-nav {
        @include xl {
          left: 0;
          right: 0;
          transform: none; } } }

    &.sub-nav-left {
      .sub-nav {
        @include xl {
          left: 0;
          transform: none; } } }

    &.sub-nav-right {
      .sub-nav {
        @include xl {
          left: auto;
          right: 0;
          transform: none; }

        &__arrow {
          @include xl {
            left: auto !important;
            transform: translate(50%, -50%) rotate(45deg); } } } }


    &:not(:last-child) {
      margin-bottom: .5rem;
      @include xxl {
        margin-bottom: 0;
        margin-right: 2.5rem; } } } }

.sub-nav {
  display: none;
  @include paragraph-medium-1;
  @include xxl {
    display: block !important;
    position: absolute;
    top: calc(100% + 1.71875rem);
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    background-color: $dark-100;
    border-radius: .625rem;
    margin-top: 1rem;
    transition: opacity .3s ease, margin-top .3s ease; }
  $self: &;
  &:before {
    @include xxl {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 1.71875rem;
      left: 0;
      bottom: 100%; } }

  &__arrow {
    position: absolute;
    display: none;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: .25rem;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: $dark-100;
    z-index: -1;
    @include xxl {
      display: block; } }

  &__title {
    position: relative;
    color: $light-100;
    @include xxl {
      padding-bottom: .625rem;
      border-bottom: 1px solid rgba($gray-500, .2); }

    &:not(:last-child) {
      margin-bottom: .5rem; } }

  &__content {
    @include xxl {
      display: flex;
      padding: 1.25rem 1.875rem;
      max-height: calc(100vh - 9.0625rem);
      //9.0625rem = header height
      overflow-y: auto; }

    & > li {
      &:not(:last-child) {
        margin-bottom: .9375rem;
        @include xxl {
          margin-bottom: 0;
          margin-right: 4.375rem; }

        #{$self}__title {
          &:after {
            @include xxl {
              position: absolute;
              content: '';
              display: block;
              width: 4.375rem;
              height: 1px;
              background-color: rgba($gray-500, .2);
              top: 100%;
              left: 100%; } } } } }

    ul {
      color: $white;
      @include xxl {
        min-width: 16.0625rem; }

      & > li {
        &:not(:last-child) {
          margin-bottom: .5rem; }

        a {
          position: relative;
          display: flex;
          align-items: center;
          text-decoration: none;
          padding: .625rem;
          color: $white;
          @include gradient-border(true, .625rem, $border-gradient-alt);
          transition: background-color .3s ease;

          &:hover {
            background-color: rgba($black, 0.4); }

          @include xxl {
            &:before {
              transition: opacity .3s ease;
              opacity: 0; }
            &.is-active, &:hover {
              &:before {
                opacity: 1; } } }


          img {
            width: 1.625rem;

            &:not(:last-child) {
              margin-right: 1rem; } } } } } } }


