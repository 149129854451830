.socials {
  &__content {
    display: flex;
    align-items: center; }

  &__item {
    width: 2.5rem;
    height: 2.5rem;
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($green, .15);
    border-radius: 50%;
    transition: background-color .3s ease;
    @include xl {
      width: 3.4375rem;
      height: 3.4375rem;
      padding: 1rem; }
    img {
      transition: transform .3s ease; }

    &:hover {
      background-color: rgba($green, .25);
      img {
        transform: scale(1.1); } }

    &:not(:last-child) {
      margin-right: 1.5625rem; } } }
