.currency {
  position: relative;
  z-index: 1;
  &__logos, &__name, &__slug {
    vertical-align: middle;
    display: inline-block; }
  &__logos {
    line-height: 0;
    img {
      width: 2rem;
      border-radius: 50%;
      border: .1875rem solid $white;
      box-shadow: 1px 7px 9px rgba(3, 2, 41, 0.07);
      &:not(:first-child) {
        margin-left: -1rem;
        position: relative;
        z-index: -1; } }
    &:not(:last-child) {
      margin-right: .625rem; } }

  &__name {
    font-weight: 700;

    &:not(:last-child) {
      margin-right: .3125rem; } }

  &__slug {
    color: $light-100; } }


