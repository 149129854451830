h1, .h1 {
  @include h1; }

h2, .h2 {
  @include h2; }

h3, .h3 {
  @include h3; }

h4, .h4 {
  @include h4; }

h5, .h5 {
  @include h5; }

h6, .h6 {
  @include h6; }

.h7 {
  @include h7; }

.paragraph-extra-1 {
  @include paragraph-extra-1; }

.paragraph-extra-2 {
  @include paragraph-extra-2; }

.paragraph-extra-3 {
  @include paragraph-extra-3; }

.paragraph-extra-4 {
  @include paragraph-extra-4; }

.paragraph-large-1 {
  @include paragraph-large-1; }

.paragraph-large-2 {
  @include paragraph-large-2; }

.paragraph-large-3 {
  @include paragraph-large-3; }

.paragraph-medium-1 {
  @include paragraph-medium-1; }

.paragraph-medium-2 {
  @include paragraph-medium-2; }

.paragraph-medium-3 {
  @include paragraph-medium-3; }

.paragraph-small-1 {
  @include paragraph-small-1; }

.paragraph-small-2 {
  @include paragraph-small-2; }

p {
  margin-bottom: 0;

  &:not(:last-child) {
    margin-bottom: .625rem; } }

.paragraph-mb-lg {
  p {
    &:not(:last-child) {
      margin-bottom: 1.25rem; } } }

.text-highlighted {
  &, &-sm {
    background-image: linear-gradient(90deg, rgba(255, 0, 0, 0.71) 0%, rgba(255, 0, 0, 0) 95.81%);
    background-size: 100% 0.3em;
    background-repeat: no-repeat;
    background-position: left 81%;
    transition: background-size .9s ease;

    &:not(.is-visible) {
      background-size: 0 0.3em; } }

  &-sm {
    background-size: 100% 0.25em;
    background-position: left 89%; } }

a {
  transition: color .3s ease; }

.color-green {
  color: $green; }

.color-dark-blue {
  color: $blue-dark; }

.color-blue {
  color: $blue; }

.text-decoration-none {
  text-decoration: none; }
