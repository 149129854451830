.footer {
  background-color: $black;
  color: $light-100;
  font-size: .8125rem;
  font-weight: 400;
  line-height: 1.5;
  @include xl {
    font-size: .875rem; }

  &__top {
    padding: 1.25rem 0;
    @include md {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    @include xl {
      padding: 1.875rem 0 1.225em; } }

  &__top, &__menu, &__additional {
    border-bottom: 1px solid rgba($light-100, .2); }

  &__logo {
    &:not(:last-child) {
      margin-bottom: 1rem;
      @include md {
        margin-bottom: 0;
        margin-right: 1.25rem; } } }

  &__menu {
    padding: 1.25rem 0;
    @include xl {
      padding: 3.125rem 0; }

    &-content {
      @include xl {
        display: flex;
        margin: -1rem;
        flex-wrap: nowrap;
        justify-content: space-between; } } }

  &__nav {
    width: 100%;
    @include xl {
      margin: 1rem;
      width: auto; }
    &:not(:last-child) {
      @include xl-max {
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid rgba($light-100, .2); } } }

  &__additional {
    padding: 1.25rem 0;
    display: flex;
    flex-direction: column-reverse;
    @include md {
      flex-direction: row;
      align-items: center;
      justify-content: space-between; } }

  &__copyright {
    &:not(:last-child) {
      margin-top: 1rem;
      @include md {
        margin-top: 0;
        margin-right: 1.25rem; } } }

  &__sub-nav {
    nav {
      display: flex;
      flex-direction: column-reverse;
      margin: -.25rem;
      @include md {
        flex-direction: row;
        flex-wrap: wrap; }
      @include xl {
        margin: -.75rem; }

      a {
        color: $light-100;
        text-decoration: none;
        margin: .25rem;
        @include xl {
          margin: .75rem; }

        &:hover {
          color: $white; } } } }

  &__info {
    padding: 1.25rem 0;
    @include xl {
      padding: 1.875rem 0 1.25rem; } } }

