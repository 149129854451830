.nav-menu {
  color: $white;

  $self: &;

  &__title {
    display: flex;
    align-items: center;
    @include xl-max {
      cursor: pointer; }

    &-text, &-icon {
      display: inline-block; }

    &-text {
      &:not(:last-child) {
        margin-right: auto; } }

    &-icon {
      width: .6875rem;
      line-height: 0;
      transition: transform .3s ease;
      @include xl {
        display: none; }

      img {
        width: 100%; }

      &:not(:first-child) {
        margin-left: .5rem; } } }

  &__content {
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    display: none;
    padding-top: .625rem;
    @include xl {
      padding-top: .9375rem;
      display: block !important; } }

  &__item {
    a {
      color: $gray-100;
      text-decoration: none;

      &:hover {
        color: $green; } }

    &:not(:last-child) {
      margin-bottom: .625rem; } }

  &.is-active {
    #{$self}__title-icon {
      transform: rotate(180deg); } } }
