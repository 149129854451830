.section {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  @include xl {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem; }
  $self: &;

  &__subtitle {
    &:not(:last-child) {
      margin-bottom: .625rem; }

    &_mb-lg {
      &:not(:last-child) {
        margin-bottom: 1rem;
        @include xl {
          margin-bottom: 1.25rem; } } } }

  &__title {
    &:not(:last-child) {
      margin-bottom: 1rem;
      @include xl {
        margin-bottom: 1.25rem; } }

    &_mb-lg {
      &:not(:last-child) {
        margin-bottom: 1.25rem;
        @include xl {
          margin-bottom: 1.75rem; } } }

    &_mt {
      margin-top: 3.125rem; } }

  &__description {
    a {
      color: inherit;
      text-decoration: none; }

    &:not(:last-child) {
      margin-bottom: 1.35rem;
      @include xl {
        margin-bottom: 1.875rem; } }

    &_mb-lg {
      &:not(:last-child) {
        margin-bottom: 1.75rem;
        @include xl {
          margin-bottom: 2.5rem; } } }

    &_full {
      max-width: unset !important; } }

  &__content {
    &:not(:first-child) {
      margin-top: 1.625rem;
      @include xl {
        margin-top: 3.125rem; } }

    &_mt-sm {
      &:not(:first-child) {
        @include xl {
          margin-top: 2.5rem; } } }

    &_mt-lg {
      &:not(:first-child) {
        margin-top: 2.125rem;
        @include xl {
          margin-top: 4.0625rem; } } } }

  &__footer {
    display: flex;
    justify-content: center;

    &:not(:first-child) {
      margin-top: 2rem;
      @include xl {
        margin-top: 3.125rem; } }

    &_mt-sm {
      &:not(:first-child) {
        margin-top: 1rem;
        @include xl {
          margin-top: 1.25rem; } } }

    &-info {
      font-size: .875rem;
      line-height: 1.5;
      font-weight: 400;
      color: $light-100; } }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -.5rem;
    @include md {
      margin: -.9375rem; }

    .icon-btn, .btn {
      margin: .5rem;
      @include md {
        margin: .9375rem; } } }

  &__circle {
    position: absolute;
    width: 19.4375rem;
    height: 19.4375rem;
    background-color: $green;
    border-radius: 50%;
    z-index: -1;
    filter: blur(280px);
    pointer-events: none;

    &_xxs {
      width: 12.5625rem;
      height: 12.5625rem;
      filter: blur(240px); }

    &_xs {
      width: 13rem;
      height: 13rem; }

    &_sm {
      width: 13.5rem;
      height: 13.5rem; }

    &_md {
      width: 18.0625rem;
      height: 18.0625rem; }

    &_lg {
      width: 18.8125rem;
      height: 18.8125rem; }

    &_xl {
      width: 23.625rem;
      height: 23.625rem;
      filter: blur(335px); } }

  &_center {
    position: relative;

    #{$self}__subtitle, #{$self}__title, #{$self}__description {
      text-align: center;
      margin-left: auto;
      margin-right: auto; }

    #{$self}__title {
      max-width: 52.5rem; }

    #{$self}__description {
      max-width: 46.875rem;

      &.h6 {
        max-width: 57.5rem; } } }

  &_pb-xl {
    padding-bottom: 6.25rem;
    @include xl {
      padding-bottom: 12.5rem; } }

  &_pt-xl {
    padding-top: 10.125rem; } }

.section, .double-section {
  position: relative;
  z-index: 1;

  &__grid {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    pointer-events: none;
    min-width: 100%;

    img {
      width: auto;
      max-width: unset;
      min-width: 100%; } }

  &_dark {
    background-color: $black;
    color: $white;

    .section__description {
      color: $gray-100; } }

  &_gray {
    background: linear-gradient(180deg, #F4F4F4 0%, rgba(244, 244, 244, 0) 100%); }

  &__decor-lines {
    position: absolute;
    display: block;
    z-index: -1;
    bottom: -12rem;
    left: 0;
    line-height: 0;
    pointer-events: none;

    img, svg {
      width: auto;
      height: auto;
      max-width: 100%; }

    &_right {
      left: auto;
      right: 0; }

    &_minh-100 {
      min-height: 100%;

      img, svg {
        min-height: 100%;
        max-width: unset; } }

    &_mob-minh-100 {
      @include xl-max {
        min-height: 100%;

        img, svg {
          min-height: 100%;
          max-width: unset; } } }

    &_horizontal {
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 50%);
      min-width: 100%;

      img, svg {
        min-width: 100%; } }

    &_vertical-center {
      img, svg {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%); } } } }


.double-section {
  .section {
    & + .section {
      padding-top: 0; } }

  &.double-section_decor-between {
    .section {
      &:first-child {
        z-index: 2;

        & + .section {
          padding-top: 5.0625rem;
          margin-top: -1px;
          @include xl {
            padding-top: 10.125rem; }

          &:before {
            position: absolute;
            content: '';
            display: block;
            height: 5.71875rem;
            width: .1875rem;
            top: -1.875rem;
            left: 50%;
            transform: translate(-50%, 0);
            background: linear-gradient(0deg, rgba(255, 0, 0, 0.71) 0%, rgba(255, 0, 0, 0) 95.81%);
            transition: height .6s ease;
            @include xl {
              height: 11.4375rem;
              top: -3.75rem; } }

          &:not(.is-visible) {
            &:before {
              height: 0; } } } } }

    &-sm {
      position: relative;

      .section {
        &:first-child {
          & + .section {
            @include xl {
              padding-top: 7.125rem; }

            &:before {
              @include xl {
                top: -6rem; } } } } } } } }

.inner-section {
  position: relative;
  padding: 1rem;
  border-radius: 1.25rem;
  background-color: $black;
  color: $white;
  z-index: 1;
  @include md {
    padding: 2.5rem; }
  @include xl {
    padding: 3.125rem; }


  $self: &;

  &__main {
    @include xl {
      display: flex; }

    &:not(:last-child) {
      margin-bottom: 1.875rem;
      @include xl {
        margin-bottom: 2.5rem; } } }

  &__medias {
    display: flex;
    align-items: flex-end;
    @include xl {
      width: 48.5%; }

    &:not(:last-child) {
      margin-bottom: 2rem;
      @include xl {
        margin-bottom: 0;
        margin-right: 1.25rem; } } }

  &__media {
    line-height: 0;

    &:not(:last-child) {
      margin-right: 1.125rem; }

    &_iphone {
      position: relative;
      width: 21.9%;
      flex-shrink: 0;

      #{$self}__media-inner {
        margin: -40%; } }

    &_ipad {
      position: relative;

      #{$self}__media-inner {
        margin: -12%; } } }

  &__text {
    @include xl {
      width: 46.25%;
      margin-left: auto; } }

  &__subtitle {
    &:not(:last-child) {
      margin-bottom: .625rem; } }

  &__title {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
      @include xl {
        margin-bottom: 1.875rem; } } }

  &__tags {
    &:not(:last-child) {
      margin-bottom: 1.875rem;
      @include xl {
        margin-bottom: 2.5rem; } }

    .tags {
      &__content {
        margin: 0 -1rem;
        padding: 0 1rem;
        @include md {
          margin: 0 -2.5rem;
          padding: 0 2.5rem; }
        @include xl {
          margin: 0 -3.125rem;
          padding: 0 3.125rem; } } } }

  &__footer {
    display: flex;
    justify-content: center; } }
