$white: #FFFFFF;
$primary: #0B251C;
$secondary: #00E6A0;
$accent: #E13A3A;
$black: #0A0903;
$dark: #0D0D0D;
$dark-100: #2B2B2B;
$light-100: #B7B7B7;
$light: #454545;
$gray: #D2D2D2;
$gray-100: #F4F4F4;
$gray-200: #F5F5F5;
$gray-300: #333333;
$gray-500: #E0E0E0;
$gray-600: #D6D6D6;
$gray-700: #2D2C27;
$gray-800: #5D5D5D;
$green: #73E355;
$red: $accent;
$blue: #348AA7;
$blue-dark: #2A6478;
$blue-light: #d6e6ed;

$border-gradient-alt: radial-gradient(16.27% 30.79% at -0.08% 0%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(24.21% 39.21% at 0% 0%, rgba(255, 255, 255, 0.81) 0%, rgba(255, 255, 255, 0.19) 100%), radial-gradient(98% 49.86% at 100.03% 100%, #348AA7 0%, rgba(52, 138, 167, 0.05) 100%);
$border-gradient-alt-2: radial-gradient(16.27% 30.79% at -0.08% 0%, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%) border-box, radial-gradient(24.21% 39.21% at 0% 0%, rgba(255, 255, 255, 0.81) 0%, rgba(255, 255, 255, 0.19) 100%) border-box, radial-gradient(98% 49.86% at 100.03% 100%, #348AA7 0%, rgba(52, 138, 167, 0.05) 100%) border-box;

$xxl: 1260;
$xl: 1024;
$lg: 992;
$md: 768;
$sm: 599;
$xs: 480;
