.header {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.3rem;
  transition: background-color .3s ease;
  @include xxl {
    height: 9.0625rem; }
  $self: &;

  &__top {
    padding: .5625rem 0;
    border-bottom: 1px solid rgba($gray-200, .2);
    display: none;
    @include xxl {
      display: block; }

    &-content {
      display: flex;
      align-items: center;
      justify-content: flex-end; } }

  &__language-switcher {
    &:not(:first-child) {
      @include xxl {
        margin-left: 1.875rem; } } }

  &__main {
    padding: 1.25rem 0;
    @include xxl {
      padding: 1rem 0; }

    &-content {
      display: flex;
      align-items: center;
      @include xxl {
        position: relative; } }

    #{$self}__top-btn, #{$self}__language-switcher {
      @include xxl {
        display: none; } }

    #{$self}__language-switcher {
      margin-left: auto; } }

  &__burger {
    display: flex;
    margin-left: 1rem;
    @include xxl {
      display: none; } }

  &__content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: calc(100vh - 4.3rem);
    // 4.3rem = header height
    display: none;
    background-color: $black;
    border-top: 1px solid $gray-700;
    @include xxl {
      position: static;
      flex-grow: 1;
      display: flex !important;
      align-items: center;
      height: auto;
      background-color: transparent;
      border-top: none; }

    .btn {
      @include xxl-max {
        display: block; }

      &:not(:last-child) {
        @include xxl-max {
          margin-bottom: 1rem; } } }

    &-inner {
      padding: 0 1rem 1rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include xxl {
        padding: 0;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: unset; } }

    &-buttons {
      flex-shrink: 0; } }

  &__logo {
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 2.4375rem; } }

  &__nav-wrapper {
    position: relative;
    overflow: hidden;
    margin: 0 -1rem;
    padding: 0 1rem;
    @include xxl {
      position: static;
      overflow: unset;
      margin: 0 auto 0 0;
      padding: 0; }

    &:after {
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3rem;
      background: linear-gradient(179.62deg, rgba(10, 9, 3, 0) 0.32%, #0A0903 63.11%);
      pointer-events: none;
      @include xxl {
        content: none; } }

    &:not(:last-child) {
      padding-bottom: 1rem;
      @include xxl {
        padding-bottom: 0; } } }

  &__nav {
    flex-shrink: 1;
    overflow-y: auto;
    padding-bottom: 1rem;
    height: 100%;
    @include xxl {
      flex-shrink: unset;
      overflow-y: unset;
      padding-bottom: 0;
      height: auto; }

    @include xxl-max {
      margin-left: -1rem;
      margin-right: -1rem;
      .nav {
        &__item {
          padding-left: 1rem;
          padding-right: 1rem; } }
      .sub-nav {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: .9375rem; } } }

  &.is-opened {
    @include xxl-max {
      background-color: $black; } }

  &_static {
    position: relative; } }

.burger {
  width: 1.5rem;
  height: 1.5rem;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;

  span {
    display: block;
    width: 100%;
    height: .125rem;
    background-color: $white;
    border-radius: 1px;
    transition: opacity .3s ease, background-color .3s ease, transform .3s ease;
    @include not-header-opened {
      @include page-gray {
        background-color: $black; } }

    &:not(:last-child) {
      margin-bottom: .25rem; } }

  &.is-active {
    span {
      &:nth-child(1) {
        transform: translate(0, 6px) rotate(45deg); }

      &:nth-child(2) {
        opacity: 0;
        transform: rotate(45deg); }

      &:nth-child(3) {
        transform: translate(0, -6px) rotate(-45deg); } } }

  &:hover {
    span {
      background-color: $green; } } }
