// Open Sans
@font-face {
  font-family: 'Open Sans';
  src: local('OpenSans Regular'), local('OpenSans-Regular'),
  url("../fonts/OpenSans-Regular.woff2") format("woff2"),
  url("../fonts/OpenSans-Regular.woff") format("woff"),
  url('../fonts/OpenSans-Regular.ttf') format('truetype') {}
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  src: local('OpenSans SemiBold'), local('OpenSans-SemiBold'),
  url("../fonts/OpenSans-SemiBold.woff2") format("woff2"),
  url("../fonts/OpenSans-SemiBold.woff") format("woff"),
  url('../fonts/OpenSans-SemiBold.ttf') format('truetype') {}
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  src: local('OpenSans Bold'), local('OpenSans-Bold'),
  url("../fonts/OpenSans-Bold.woff2") format("woff2"),
  url("../fonts/OpenSans-Bold.woff") format("woff"),
  url('../fonts/OpenSans-Bold.ttf') format('truetype') {}
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

// Sora
@font-face {
  font-family: 'Sora';
  src: local('Sora SemiBold'), local('Sora-SemiBold'),
  url("../fonts/Sora-SemiBold.woff2") format("woff2"),
  url("../fonts/Sora-SemiBold.woff") format("woff"),
  url('../fonts/Sora-SemiBold.ttf') format('truetype') {}
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Sora';
  src: local('Sora Bold'), local('Sora-Bold'),
  url("../fonts/Sora-Bold.woff2") format("woff2"),
  url("../fonts/Sora-Bold.woff") format("woff"),
  url('../fonts/Sora-Bold.ttf') format('truetype') {}
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

