// Decor lines
.decor-lines-wrapper-1300 {
  .decor-line-path {
    stroke-dasharray: 1300;
    stroke-dashoffset: -1300; } }

.decor-lines-wrapper-1400 {
  .decor-line-path {
    stroke-dasharray: 1400;
    stroke-dashoffset: -1400; } }

.decor-lines-wrapper-1500 {
  .decor-line-path {
    stroke-dasharray: 1500;
    stroke-dashoffset: -1500; } }

.decor-lines-wrapper-2000 {
  .decor-line-path {
    stroke-dasharray: 2000;
    stroke-dashoffset: -2000; } }

.decor-lines-wrapper-2000-invert {
  .decor-line-path {
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000; } }

.decor-line-path {
  stroke-dasharray: 1000;
  stroke-dashoffset: -1000;
  transition: stroke-dashoffset 3s ease;

  &, &-1300, &-1400 {
    &.is-visible {
      stroke-dashoffset: 0; } } }


// Animation classes
.translate-top-opacity {
  transition: transform .6s ease, opacity .6s ease;

  &:not(.is-visible) {
    transform: translate(0, 2rem);
    opacity: 0; } }

.translate-left-opacity {
  transition: transform .6s ease, opacity .6s ease;

  &:not(.is-visible) {
    transform: translate(2rem, 0);
    opacity: 0; } }

.translate-right-opacity {
  transition: transform .6s ease, opacity .6s ease;

  &:not(.is-visible) {
    transform: translate(-2rem, 0);
    opacity: 0; } }

.scale-opacity {
  transition: transform .6s ease, opacity .6s ease;

  &:not(.is-visible) {
    transform: scale(0.25);
    opacity: 0; } }
