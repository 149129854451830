html {
  font-size: 16px; }

body {
  background-color: $white;
  color: $black;
  @include OpenSans;
  @include paragraph-medium-3;

  &.page-gray {
    background-color: $gray-100; } }

.out {
  overflow: hidden; }

.logo {
  display: block;
  width: 5.625rem;
  line-height: 0;
  position: relative;
  @include md {
    width: 7.5rem; }
  @include xl {
    width: 8.125rem; }

  &__img {
    transition: opacity .3s ease;
    @include not-header-opened {
      @include page-gray {
        opacity: 0; } }

    &_dark {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      @include not-header-opened {
        @include page-gray {
          opacity: 1; } } } }

  img {
    width: 100%;
    height: auto; }

  &_lg {
    width: 8.75rem;
    @include xl {
      width: 11.75rem; } }

  &_xl {
    width: 9rem;
    @include xl {
      width: 12.5rem; } } }

img {
  max-width: 100%; }

.overflow-hidden {
  overflow: hidden; }

.z-2 {
  z-index: 2 !important; }

.pt-0 {
  padding-top: 0 !important; }

img.image-br {
  border-radius: .625rem; }

.text-center {
  text-align: center; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.more-tick {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 11.9629L6 6.96289L1 1.96289' stroke='%232A6478' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  padding-right: .7rem; }
