.show-more-target {
  display: none; }

.show-more-trigger {
  display: inline-flex;
  align-items: baseline;
  background: transparent;
  color: inherit;
  transition: color .3s ease;
  font-family: inherit;
  $self: &;

  &__text {
    &:not(:last-child) {
      margin-right: .5rem; } }

  &__icon {
    width: .75em;
    line-height: 0;
    display: inline-block;
    transition: transform .3s ease;

    svg {
      width: 100%;
      height: auto; } }

  &.is-active {
    #{$self}__icon {
      transform: rotate(180deg); } }

  &:hover {
    color: $green; } }

