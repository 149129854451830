.btn {
  position: relative;
  z-index: 1;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  transition: background-color .3s ease, color .3s ease;
  $self: &;

  &_green, &_green-outlined, &_white-outlined, &_black-outlined {
    @include Sora;
    font-size: 1.125rem;
    line-height: 1.3;
    font-weight: 600;
    border-radius: .5rem 0;
    padding: .75rem 2.5rem;
    border: 5px solid transparent;
    position: relative;

    &.btn_sm {
      font-size: 1rem;
      padding: .75rem 1.28125rem; }

    &.btn_xs {
      font-size: 1rem;
      padding: .4375rem 1.21875rem; }

    #{$self}__bg-wrapper, #{$self}__bg, #{$self}__bg-hover {
      position: absolute;
      content: '';
      z-index: -1;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: transform .3s ease, opacity .3s ease;
      pointer-events: none; }

    #{$self}__bg-wrapper {
      border-radius: .5rem 0;
      overflow: hidden; }

    #{$self}__bg {
      background: linear-gradient(87.71deg, #73E355 0%, #61E03E 102.43%); }

    #{$self}__bg-hover {
      background: linear-gradient(87.71deg, #73E355 0%, #24532A 102.43%);
      transform: translate(calc(100% - 1px), 0); }

    #{$self}__subtext {
      display: block;
      font-size: 0.75rem;
      font-weigth: 400px;
      @include OpenSans; } }


  &_green {
    color: $black;

    &:before, &:after {
      position: absolute;
      content: '';
      display: block;
      width: calc(100% + 0px + 1px);
      height: calc(100% + 0px + 1px);
      border-radius: .5rem 0;
      background: radial-gradient(circle, #73E355 0%, #24532A 100%);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
      transition: transform .3s ease, width .3s ease, height .3s ease; }

    &:before {
      top: -5px;
      left: -5px;
      border-top: 2px solid transparent;
      border-left: 2px solid transparent; }

    &:after {
      bottom: -5px;
      right: -5px;
      border-bottom: 2px solid transparent;
      border-right: 2px solid transparent; }

    &:hover {
      #{$self}__bg {
        transform: translate(-100%, 0); }

      #{$self}__bg-hover {
        transform: translate(0, 0); }

      &:before, &:after {
        width: calc(100% + 9px + 1px);
        height: calc(100% + 9px + 1px); } } }


  &_green-outlined, &_white-outlined, &_black-outlined {
    position: relative;

    #{$self}__bg, #{$self}__bg-hover {
      border-radius: .5rem 0; }

    #{$self}__bg {
      opacity: 0;
      transform: scaleX(.95); }

    #{$self}__bg-hover {
      display: none; }

    &:before {
      position: absolute;
      content: '';
      display: block;
      width: calc(100% + 5px * 2);
      height: calc(100% + 5px * 2);
      border-radius: .5rem 0;
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
      top: -5px;
      left: -5px;
      border: 2px solid transparent; }

    &:hover {
      color: $black;

      #{$self}__bg {
        opacity: 1;
        transform: none; } }

    &.btn_xs {
      padding: .25rem .96875rem;
      border: 5px transparent solid;

      &:before {
        border: 1px transparent solid; }

      &:before {
        top: -5px;
        left: -5px;
        width: calc(100% + 5px * 2);
        height: calc(100% + 5px * 2); }

      &, #{$self}__bg, #{$self}__bg-hover {
        border-radius: .3125rem 0; } } }

  &_green-outlined {
    color: $green;

    &:before {
      background: radial-gradient(circle, #73E355 0%, #24532A 100%); }

    &.btn_text-black {
      color: $black; } }

  &_white-outlined {
    color: $white;

    #{$self}__bg {
      background: $white; }

    &:before {
      background: radial-gradient(circle, #ffffff 0%, #ffffff 100%); } }

  &_black-outlined {
    color: $black;

    #{$self}__bg {
      background: $black; }

    &:before {
      background: radial-gradient(circle, $black 0%, $black 100%); }

    &:hover {
      color: $white; } }

  &_simple {
    font-size: .875rem;
    line-height: 1.5;
    font-weight: 400;
    @include OpenSans;
    padding: .5rem 2.5rem;
    border-radius: .3125rem; }

  &_simple-outlined {
    background-color: transparent;
    border: 1px solid $light-100;
    color: $gray-300;

    &:hover {
      background-color: $light-100; } }

  &_simple-red {
    background-color: $red;
    color: $white;

    &:hover {
      background-color: rgba($red, .7); } }

  &_simple-green {
    background-color: $green;
    color: $white;

    &:hover {
      //background-color: rgba($green, .7)
      background-color: $secondary; } }

  //&.is-visible
  //  &.btn_green
  //    &:before, &:after
  //      width: calc(100% + 5px + 1px)
 }  //      height: calc(100% + 5px + 1px)

.icon-btn {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 1.0625rem;
  background-color: $white;
  @include gradient-border(true, .9375rem, radial-gradient(100% 94.74% at 100% 94.74%, rgba(52, 138, 167, 0.5) 0%, rgba(52, 138, 167, 0.025) 100%) border-box);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.04), 0 .5rem 1rem rgba(0, 0, 0, 0.08);
  color: $black;
  text-decoration: none;
  transition: transform .3s ease;
  min-width: 18.125rem;
  @include md {
    min-width: 21.25rem; }

  &:after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: .9375rem;
    top: 0;
    left: 0;
    background: linear-gradient(356.38deg, #69AFC7 -226.66%, rgba(255, 255, 255, 0) 366.26%);
    opacity: 0;
    transition: opacity .3s ease; }

  @include md {
    font-size: 1.125rem;
    padding: 1.875rem; }
  $self: &;

  &:hover {
    background-color: transparent;

    &:after {
      opacity: 1; } }

  &__icon {
    width: 1.75rem;
    line-height: 0;

    img {
      width: 100%;
      height: auto; } }

  &__text {
    &:not(:first-child) {
      margin-left: 1rem;
      @include md {
        margin-left: 1.25rem; } }

    &:not(:last-child) {
      margin-right: 1rem;
      @include md {
        margin-right: 1.25rem; } } }

  &__subtext {
    font-size: 1.25rem;
    line-height: 0.95; }

  &_lg {
    position: relative;
    padding: 1.0625rem 1.375rem;
    min-width: 10.125rem;
    @include md {
      min-width: 16.875rem;
      padding: 2.0625rem 3.375rem; }

    #{$self}__icon {
      width: 1.6875rem;
      @include md {
        width: 2.25rem; } }

    #{$self}__text {
      position: relative;
      font-size: 35.25px;
      font-weight: 700;
      line-height: 1;
      background: linear-gradient(117.99deg, #69AFC7 17.58%, #153138 177.57%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @include md {
        font-size: 47px; }

      .delimiter {
        position: absolute;
        content: '';
        display: block;
        width: .03em;
        height: .75em;
        top: 58%;
        background-color: $black;
        transform: translate(0, -50%); }

      &:not(:first-child) {
        padding-top: .125em;
        margin-left: .5rem;
        @include md {
          margin-left: .625rem; } }

      &:not(:last-child) {
        margin-right: .5rem;
        @include md {
          margin-right: .625rem; } }

      &:not(:first-child):last-child {
        padding-left: .19em;

        .delimiter {
          left: 0; } }

      &:not(:last-child):first-child {
        padding-right: .24em;

        .delimiter {
          right: 0;
          top: 50%; } } } } }
