.language-switcher {
  position: relative;
  z-index: 1;

  $self: &;

  &__item {
    display: flex;
    align-items: center;
    color: $white;
    text-decoration: none;
    transition: color .3s ease;
    @include not-header-opened {
      @include page-gray {
        color: $black; } }

    &-img {
      width: 1.25rem;
      height: 1.25rem;
      object-fit: contain;

      &:not(:last-child) {
        margin-right: .5rem; } }

    &-name {
      &:not(:last-child) {
        margin-right: .3125rem; } }

    &-arrow {
      line-height: 0;
      transition: transform .3s ease;

      svg {
        width: 7px;
        height: auto; } } }

  a#{$self}__item {
    &:hover {
      color: $green; } }

  &__selected {}

  &__content {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    left: 0;
    top: 100%;
    transition: opacity .3s ease; }

  &:hover {
    #{$self}__content {
      opacity: 1;
      pointer-events: auto; }

    #{$self}__item {
      &-arrow {
        transform: rotate(180deg); } } } }
